import img_1 from '../../src/assets/images/blog_1.webp'
import img_2 from '../../src/assets/images/blog_2.webp'
import img_3 from '../../src/assets/images/blog_3.webp'
import img_4 from '../../src/assets/images/blog_4.webp'

export const blogData = [
  { id: 1, image: img_1 },
  { id: 2, image: img_2 },
  { id: 3, image: img_3 },
  { id: 4, image: img_4 },
]
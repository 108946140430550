import React, { useState } from "react";
import Router from "next/router";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

import Styles from "../styles/Arrow.module.scss";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 80) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 80) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    scrollTo({ top: 0, behavior: "smooth" });
    Router.push("/");
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <ArrowCircleUpIcon
      className={Styles.scrollTop}
      onClick={scrollTop}
      style={{ display: showScroll ? "flex" : "none" }}
    />
  );
};

export default ScrollArrow;

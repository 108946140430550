import CareerOne from '../../src/assets/images/careerOne.svg'
import CareerTwo from '../../src/assets/images/careerTwo.svg'
import CareerThree from '../../src/assets/images/careerThree.svg'
import CareerFour from '../../src/assets/images/careerFour.svg'
import CareerFive from '../../src/assets/images/careerFive.svg'
import CareerSix from '../../src/assets/images/careerSix.svg'

import image_1 from '../../src/assets/images/image_1.webp'
import image_2 from '../../src/assets/images/image_2.webp'
import image_3 from '../../src/assets/images/image_3.webp'
import image_4 from '../../src/assets/images/image_4.webp'
import image_5 from '../../src/assets/images/image_5.webp'
import image_6 from '../../src/assets/images/image_6.webp'
import image_7 from '../../src/assets/images/image_7.webp'
import image_8 from '../../src/assets/images/image_8.webp'
import image_9 from '../../src/assets/images/image_9.webp'

import img_1 from '../../src/assets/images/img_1.svg'
import img_2 from '../../src/assets/images/img_2.svg'
import img_3 from '../../src/assets/images/img_3.svg'
import img_4 from '../../src/assets/images/img_4.svg'
import img_5 from '../../src/assets/images/img_5.svg'
import img_6 from '../../src/assets/images/img_6.svg'
import img_7 from '../../src/assets/images/img_7.svg'
import img_8 from '../../src/assets/images/img_8.svg'
import img_9 from '../../src/assets/images/img_9.svg'


export const careerData = [
  { id: 1, text: 'Ambiente descolado   (puffs, free coffee, fruit at will)', image: CareerOne },
  { id: 2, text: 'Plano de carreiras', image: CareerTwo },
  { id: 3, text: 'Acesso à plataformas de cursos', image: CareerThree },
  { id: 4, text: 'Treinamentos e workshops (internos e externos)', image: CareerFour },
  { id: 5, text: 'Ações de Integração Social', image: CareerFive },
  { id: 6, text: 'Seguro de vida', image: CareerSix },
]
export const careerImage = [
  { id: 1, image: image_1 },
  { id: 2, image: image_2 },
  { id: 3, image: image_3 },
  { id: 4, image: image_4 },
  { id: 5, image: image_5 },
  { id: 6, image: image_6 },
  { id: 7, image: image_7 },
  { id: 8, image: image_8 },
  { id: 9, image: image_9 },
]
export const careerImgLanguage = [
  { id: 1, image: img_1 },
  { id: 2, image: img_2 },
  { id: 3, image: img_3 },
  { id: 4, image: img_4 },
  { id: 5, image: img_5 },
  { id: 6, image: img_6 },
  { id: 7, image: img_7 },
  { id: 8, image: img_8 },
  { id: 9, image: img_9 },
]

export const jobCareer = [
  { id: 1, text: 'Desenvolvedor Front-End' },
  { id: 2, text: 'Desenvolvedor Mobile' },
  { id: 3, text: 'Desenvolvedor Front-End' },
  { id: 4, text: 'Desenvolvedor Full-Stack' },
]
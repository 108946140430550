import Styles from "../styles/StripJob.module.scss";
import Link from "next/link";

const StripJob = () => {
  return (
    <div id="contact" className={Styles.strip}>
      <div className="flex text-center align-center justify-center">
        <div className={Styles.cardStrip}>
          <div className={Styles.areaJob}>Não encontrou sua vaga?</div>
          <div className={Styles.areaForward}>
            <Link passHref href="mailto:oi@madatech.com.br">
              <a rel="noreferrer">ENCAMINHE SEU CURRÍCULO</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripJob;

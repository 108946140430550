import * as React from "react";
import { useState } from "react";
import Image from "next/image";
import logoImage from "../assets/images/logo.svg";
import styles from "../styles/Nav.module.scss";
import Link from "next/link";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import { useWindowSize } from "../hooks/useWindowSize";

type Anchor = "top" | "left" | "bottom" | "right";

const Header = () => {
  const size = useWindowSize();

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: 300,
        height: "100%",
        color: "#fff",
        background: "#420D55",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={styles.mobileLogo}>
        <Image alt="Logo da madatech" src={logoImage} />
      </div>
      <Divider />
      <List>
        {[
          { text: "sobre", href: "#about" },
          { text: "portfólio", href: "#portfolio" },
          { text: "contato", href: "#contact" },
          { text: "carreira", href: "#career" },
          { text: "blog", href: "#blog" },
        ].map((item) => (
          <ListItem disablePadding key={item.text}>
            <ListItemButton component="a" href={item.href}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className={styles.nav}>
      <header className={styles.header}>
        <nav className={styles.navbar}>
          <div className={styles.logoNav}>
            <Link passHref href="/">
              <Image src={logoImage} alt="Logo Madatech em navegação" />
            </Link>
          </div>
          {size.width <= 1199 ? (
            <div>
              <React.Fragment key={"right"}>
                <div className={styles.areaBars}>
                  <Button
                    className={styles.bars}
                    onClick={toggleDrawer("right", true)}
                  >
                    <DehazeOutlinedIcon />
                  </Button>
                </div>
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={toggleDrawer("right", false)}
                >
                  {list("right")}
                </Drawer>
              </React.Fragment>
            </div>
          ) : (
            <ul className={styles.navMenu}>
              <li className={styles.navItem}>
                <Link passHref href="#about">
                  sobre
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link passHref href="#portfolio">
                  portfólio
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link passHref href="#contact">
                  contato
                </Link>
              </li>
              <li className={styles.btnLink}>
                <Link passHref href="#career">
                  carreira
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link passHref href="#blog">
                  blog
                </Link>
              </li>
            </ul>
          )}
        </nav>
      </header>
    </div>
  );
};

export default Header;

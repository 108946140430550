import Image from "next/image";
import { CarrerProps } from "../dtos/carrer.types";

import styles from "../styles/Career.module.scss";

const Career = (props: CarrerProps) => {
  const content = props.career.map((career: any) => (
    <div className={styles.cardCareer} key={career.id}>
      <div className={styles.imgCardCareer}>
        <Image alt="Icones carreiras" src={career.image} />
      </div>
      <h3 className={styles.textCardCareer}>{career.text}</h3>
    </div>
  ));

  return (
    <div id="career" className={styles.career}>
      <div
        className="flex justify-center align-center flex-col lg:p-24 px-5 mt-12 lg:mt-0 lg:grid
							lg:grid-cols-5
							gap-20"
      >
        <div className="lg:col-span-2">
          <p className={styles.presentation}>CARREIRAS</p>
          <h1 className={styles.tituloPresentation}>
            Faça parte do nosso time
          </h1>
          <p className={styles.textoPresentation}>
            Para além da tecnologia, buscamos experiências! <br />
            <br />
            O diálogo e a troca de conhecimentos na equipe são ações norteadoras
            da Madatech, seja na resolução de problemas, na criação de novos
            projetos ou no suporte de nossos produtos.
            <br />
            <br />
            Aprender sempre! Incentivamos o desenvolvimento e os novos
            aprendizados que possam contribuir para uma melhor performance
            individual e da equipe no andamento dos projetos.
            <br />
            <br />
            Adotamos a metodologia OKR (um conjunto de pequenas metas para se
            alcançar um objetivo macro) para trabalhar as metas individuais,
            periodicamente acompanhadas pelo gestor.
          </p>
        </div>
        <div className="lg:col-span-3 lg:mt-10">
          <div>
            <h2 className={styles.tituloCareer}>
              Conheça mais sobre os benefícios:
            </h2>
          </div>
          <div
            className="md:grid
							md:grid-cols-3
              flex-col
							gap-2
              flex
              md:mt-20
              mt-12
              md:px-8
              justify-center
              align-center
              text-center
              "
          >
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;

import styles from "../styles/Home.module.scss";
import Typist from "react-typist";

const Home = () => {
  return (
    <div id="home" className={styles.backGroundImage}>
      <div className="h-screen flex align-center px-5 justify-center text-center flex-col">
        <Typist
          cursor={{ show: false, hideWhenDone: true, hideWhenDoneDelay: 0 }}
        >
          <h1 className={styles.titulo}>
            Vivemos o Seguro.{" "}
            <span className={styles.tituloCor}>Respiramos Tecnologia.</span>{" "}
          </h1>
        </Typist>
        <h2 className={styles.subTitulo}>
          A insurtech de uma das maiores corretoras do Brasil
        </h2>
      </div>
    </div>
  );
};

export default Home;

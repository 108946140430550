import React, { ReactNode } from "react";
import {
  careerData,
  careerImage,
  careerImgLanguage,
} from "../../public/data/careerData";
import { blogData } from "../../public/data/blogData";

import Nav from "./Nav";
import Home from "./Home";
import About from "./About";
import Portfolio from "./Portfolio";
import Career from "./Career";
import SecondCareer from "./SecondCareer";
import ThirdCareer from "./ThirdCareer";
import Job from "./Job";
import StripJob from "./StripJob";
import Blog from "./Blog";
import Footer from "./Footer";
import Arrow from "./ScrollArrow";

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  const career = careerData;
  const careerImg = careerImage;
  const careerL = careerImgLanguage;
  const blogD = blogData;

  return (
    <>
      <Nav />
      <Home />
      <Arrow />
      <About />
      <Portfolio />
      <Career career={career} />
      <SecondCareer career={careerImg} />
      <ThirdCareer career={careerL} />
      <Job />
      <StripJob />
      <Blog blog={blogD} />
      <Footer />
    </>
  );
};
export default Layout;

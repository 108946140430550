import Link from "next/link";
import Image from "next/image";
import fernando from "../assets/images/fernando.webp";
import daniel from "../assets/images/daniel.webp";
import lucas from "../assets/images/lucas.webp";
import linkedinLogo from "../assets/images/linkedinRoxo.svg";

import styles from "../styles/About.module.scss";

const About = () => {
  return (
    <div id="about" className={styles.about}>
      <div
        className="flex justify-center flex-col align-center lg:p-24 px-5 mt-12 lg:mt-0 lg:grid
							lg:grid-cols-7
							lg:gap-20"
      >
        <div className="lg:col-span-3">
          <p className={styles.presentation}>PRIMEIRAMENTE MUITO PRAZER</p>
          <h1 className={styles.tituloPresentation}>
            Olá! <br /> Somos a madatech.
          </h1>
          <p className={styles.textoPresentation}>
            Somos uma Insurtech criada em 2021 pela Madalozzo Seguros, corretora
            atuante no mercado desde 1932 e hoje presente em 6 estados do
            Brasil. <br />
            <br />
            As insurtechs estão abrindo novos caminhos no mercado de seguros,
            trazendo maior agilidade, simplificando e modernizando os processos
            que ocorrem nas corretoras.
            <br />
            <br />
            Nós somos responsáveis por todo o desenvolvimento interno das
            tecnologias e sistemas digitais da Madalozzo. <br />
            <br />
            Unimos tradição e tecnologia para trazer soluções que atendam o que
            cada geração busca em nossos produtos e serviços, prezando sempre a
            experiência do consumidor final.
          </p>
        </div>
        <div className="lg:col-span-4">
          <div className="grid	lsm:grid-cols-3 lsm:gap-6 flex lg:mt-24 mt-12 lg:px-8 px-5">
            <div className="flex flex-col text-center">
              <div className={styles.imageContainer}>
                <Image
                  layout="responsive"
                  src={lucas}
                  alt="Foto Lucas Madalozzo"
                />
              </div>
              <p className={styles.nome}>Lucas Madalozzo</p>
              <span className={styles.funcao}>PRODUTO</span>
              <div className={styles.areaSocialIcon}>
                <Link
                  passHref
                  href="https://www.linkedin.com/in/lucas-madalozzo-6ab22797/"
                >
                  <Image
                    src={linkedinLogo}
                    alt="Logo e link do linkedin do Lucas Madalozzo"
                  />
                </Link>
              </div>
            </div>
            <div className="flex flex-col text-center md:mt-0 mt-12">
              <div className={styles.imageContainer}>
                <Image
                  layout="responsive"
                  src={fernando}
                  alt="Foto Fernando Ribeiro"
                />
              </div>
              <p className={styles.nome}>Fernando Ribeiro</p>
              <span className={styles.funcao}>TECH</span>
              <div className={styles.areaSocialIcon}>
                <Link
                  passHref
                  href="https://www.linkedin.com/in/fernandoribeiro7/"
                >
                  <Image
                    src={linkedinLogo}
                    alt="Logo e link do linkedin do Fernando Ribeiro"
                  />
                </Link>
              </div>
            </div>
            <div className="flex flex-col text-center md:mt-0 md:mb-0 mt-12 mb-12">
              <div className={styles.imageContainer}>
                <Image
                  layout="responsive"
                  src={daniel}
                  alt="Foto Daniel Madalozzo"
                />
              </div>
              <p className={styles.nome}>Daniel Madalozzo</p>
              <span className={styles.funcao}>COMERCIAL</span>
              <div className={styles.areaSocialIcon}>
                <Link
                  passHref
                  href="https://www.linkedin.com/in/daniel-madalozzo-6a578b96/"
                >
                  <Image
                    src={linkedinLogo}
                    alt="Logo e link do linkedin do Daniel Madalozzo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

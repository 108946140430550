import React, { useEffect, useState } from "react";

import Styles from "../styles/Blog.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import axios from "axios";
import { format } from "date-fns";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface IBlog {
  id: string;
  title: {
    rendered: string;
  };
  date: string;
}

interface Props {
  blog: Array<{
    id: number;
    image: any;
  }>;
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: { slidesToShow: 3, slidesToScroll: 1, infinite: true },
    },
    {
      breakpoint: 600,
      settings: { slidesToShow: 2, slidesToScroll: 1, infinite: true },
    },
    { breakpoint: 480, settings: { slidesToShow: 1, infinite: true } },
  ],
};

const Blog = (props: Props) => {
  const [data, setData] = useState<IBlog[]>([]);

  const getDataAxios = async () => {
    try {
      const res = await axios.get(
        "https://blog.madaseg.com.br/wp-json/wp/v2/posts?_embed&per_page=6"
      );

      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataAxios();
  }, []);

  const content = data.map((blog, index) => (
    <div className={Styles.cardBlog} key={blog.id}>
      <div className={Styles.blogImage}>
        {props.blog[index] ? (
          <div>
            <Image
              layout="responsive"
              src={props.blog[index]?.image}
              alt="Fundo imagens do blog"
            />
          </div>
        ) : (
          <div>
            <Image
              layout="responsive"
              src={props.blog[props.blog.length - 1]?.image}
              alt="Fundo imagens do blog"
            />
          </div>
        )}
      </div>
      <div className={Styles.areaTextBlog}>
        <h1 className={Styles.textCardBlog}>{blog.title.rendered}</h1>
        <p className={Styles.textCardBlogDate}>
          {format(new Date(blog.date), "dd/MM/yyyy")}
        </p>
      </div>
    </div>
  ));

  return (
    <div id="blog" className={Styles.blog}>
      <div className="flex justify-center align-center lg:p-24 px-5 mt-12 lg:mt-0 flex-col flex">
        <div className="col-span-2">
          <p className={Styles.presentation}>SAIBA MAIS</p>
          <h1 className={Styles.tituloPresentation}>Blog</h1>
        </div>
        <div className="mt-5 text-center lg:px-8 px-5 mb-12 lg:mb-0">
          <Slider {...settings}>{content}</Slider>
        </div>
      </div>
    </div>
  );
};

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <ArrowForwardIosIcon className={Styles.arrowRight} onClick={onClick} />
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return <ArrowBackIosNewIcon className={Styles.arrowLeft} onClick={onClick} />;
}

export default Blog;

import Image from "next/image";
import Link from "next/link";
import styles from "../styles/Footer.module.scss";
import facebookImg from "../assets/images/facebook.svg";
import InstagramImg from "../assets/images/instagram.svg";
import linkedinImg from "../assets/images/linkedin.svg";
import logoImage from "../assets/images/logo.svg";
import { useCallback, useReducer, useState } from "react";
import { removeNumbers } from "../utils/removeNumbers";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import { form_LGPD_schema } from "../schema";

type formState = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formInitial = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsSend(false);
    setForm(formInitial);
    setErrors("");
  };

  const [form, setForm] = useReducer(function defaultReducer(
    state: formState,
    newState: Partial<formState>
  ) {
    return {
      ...state,
      ...newState,
    };
  },
    formInitial);

  const validateField = useCallback(
    async (key: string, value: string) => {
      try {
        await form_LGPD_schema.validateAt(key, { [key]: value });
        setErrors('');
      } catch (err: any) {
        const msg = err?.message;
        setErrors(msg);
      }
    },
    []
  );

  const handleInputChange = useCallback(
    (key: string, value: string) => {
      setForm({ [key]: value });
      validateField(key, value);
    },
    [validateField]
  );

  const sendContact = useCallback(async () => {
    try {
      setLoading(true);
      await form_LGPD_schema.validate({
        name: form.name,
        email: form.email,
        subject: form.subject,
        message: form.message,
      });

      let url =
        "https://api.madalozzoseguros.com.br/wp-json/contact-form-7/v1/contact-forms/3205/feedback";
      const params = new FormData();
      params.append("your-name", form.name);
      params.append("your-email", form.email);
      params.append("your-subject", form.subject);
      params.append("your-message", form.message);
      params.append("your-origin", "Madatech");
      params.append("your-site", "www.madatech.com.br");

      const resp = await axios.post(url, params);

      if (resp.status === 200) {
        setErrors(resp.data.message);
        setForm(formInitial);
      } else {
        setErrors("Erro ao enviar dados. Tente novamente mais tarde!");
      }

      setLoading(false);
      setIsSend(true);
    } catch (err: any) {
      setLoading(false);
      const msg = err?.response?.data?.mensagem
        ? "Erro ao enviar dados. Tente novamente mais tarde!"
        : err?.message;

      setErrors(msg);
    }
  }, [form.name, form.email, form.subject, form.message, formInitial]);

  return (
    <div id="footer" className={styles.backGroundImage}>
      <div className="lg:container lg:mx-auto">
        <div
          className="lg:grid
							lg:grid-cols-5
							lg:gap-6
              flex
              flex-col
              lg:mt-24
              "
        >
          <div className="col-span-3 flex justify-center align-center">
            <div className="flex flex-col lg:px-24 px-5 mt-5 lg:mt-0 w-full">
              <h1 className={styles.titulo}>Vivemos o seguro.</h1>
              <h2 className={styles.subTitulo}>Respiramos tecnologia.</h2>
              <div className={styles.logoFooter}>
                <Link passHref href="/">
                  <Image src={logoImage} alt="Logo Madatech no rodapé" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-2 flex flex-col align-start justify-center">
            <div className="flex flex-col lg:px-24 px-5 mt-12 lg:mt-0 w-full">
              <h1 className={styles.tituloSite}>madatech.com.br</h1>
              <h2 className={styles.tituloDireito}>
                Todos os Direitos Reservados
              </h2>
              <h3 className={styles.tituloEmail}>
                <Link passHref href="mailto:oi@madatech.com.br">
                  oi@madatech.com.br
                </Link>
              </h3>
              <div onClick={() => handleOpen()} className={styles.linkFooter}>
                LGPD
              </div>
            </div>
            <div className="flex align-center lg:px-24 px-5 my-8 w-full cursor-pointer">
              <div className={styles.areaSocialIcon}>
                <Link passHref href="https://www.instagram.com/madatech_/">
                  <Image src={InstagramImg} alt="Icone do Instagram" />
                </Link>
              </div>
              <div className={styles.alignIcon}>
                {/* <Link passHref href="/"  >
                  <Image src={facebookImg} alt='Icone do Facebook' />
                </Link> */}
              </div>
              <div className={styles.areaSocialIcon}>
                <Link
                  passHref
                  href="https://www.linkedin.com/company/vempramadatech/mycompany/"
                >
                  <Image src={linkedinImg} alt="Icone do Linkedin" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`flex flex-col justify-center items-center`}
      >
        <div
          className={`flex flex-col bg-white lg:w-4/12 lsm:w-8/12 lmd:w-6/12 w-11/12 rounded-lg py-5 px-10 relative`}
        >
          <div className="flex flex-row items-start justify-between">
            <div className="flex flex-col items-start justify-center">
              <p className={`${styles.tituloPresentation} md:text-[1.5rem] msm:text-[1.3rem] text-[1.1rem]`}>
                Temas relacionados à LGPD, entre em contato conosco:
              </p>
              <p className={`${styles.subtitleBox} msm:text-[1.1rem] text-[1rem]`}>DPO Responsável: Lucas Madalozzo</p>

            </div>

            <button onClick={handleClose} className={styles.btnFechar}>
              <CloseIcon sx={{ color: "#331597", fontSize: "30px" }} />
            </button>
          </div>
          {isSend ? (
            <>
              <p className={styles.sendContact}>Enviado com Sucesso!!</p>
              <button
                className={`${styles.btnSend} mt-5`}
                onClick={() => {
                  handleClose();
                }}
              >
                Fechar
              </button>
            </>
          ) : (
            <>
              <form
                noValidate
                className={`${styles.form} my-5 grid grid-cols-2 gap-y-2`}
              >
                <div className="flex flex-col w-full ltm:col-span-1 col-span-2">
                  <label htmlFor="nome">Nome*</label>
                  <input
                    value={removeNumbers(form.name) ?? ""}
                    onChange={(e) =>
                      handleInputChange(
                        "name",
                        removeNumbers(e.target.value?.trimStart(), true)
                      )
                    }
                    id="nome"
                    placeholder="Nome"
                    type="text"
                  />
                </div>
                <div className="flex flex-col w-full ltm:col-span-1 col-span-2">
                  <label htmlFor="email">E-mail*</label>
                  <input
                    value={form.email ?? ""}
                    onChange={(e) =>
                      handleInputChange(
                        "email",
                        e.target.value?.trimStart()
                      )
                    }
                    id="email"
                    placeholder="E-mail"
                    type="email"
                  />
                </div>
                <div className="flex flex-col w-full ltm:col-span-1 col-span-2">
                  <label htmlFor="sub">Assunto*</label>
                  <input
                    value={form.subject ?? ""}
                    onChange={(e) =>
                      handleInputChange(
                        "subject",
                        e.target.value?.trimStart()
                      )
                    }
                    id="sub"
                    placeholder="Assunto"
                    type="text"
                  />
                </div>
                <div className="flex flex-col w-full col-span-2">
                  <label htmlFor="textarea">Mensagem*</label>
                  <textarea
                    id="textarea"
                    rows={3}
                    value={form.message ?? ""}
                    onChange={(e) =>
                      handleInputChange(
                        "message",
                        e.target.value?.trimStart()
                      )
                    }
                    placeholder="Deixe sua mensagem, assim que possível entraremos em contato."
                  ></textarea>
                </div>
              </form>

              <div className={styles.separator}>
                <button
                  className={styles.btnSend}
                  onClick={() => {
                    sendContact();
                  }}
                >
                  {loading ? "Enviando..." : "Enviar"}
                </button>
              </div>

              {errors && (
                <span className={`${styles.error} text-center mt-3`}>
                  {errors}
                </span>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Footer;

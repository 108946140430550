import React, { useState } from "react";
import Image from "next/image";
import Typed from "react-typed";
import styles from "../styles/Career.module.scss";
import { CarrerProps } from "../dtos/carrer.types";

const ThirdCareer = (props: CarrerProps) => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 3200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 3200) {
      setShowScroll(false);
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const content = props.career.map((career) => (
    <div key={career.id}>
      <div className={styles.imgCardThirdCareer}>
        <Image
          layout="responsive"
          alt="Imagens Madatech na Madalozzo Seguros"
          src={career.image}
        />
      </div>
    </div>
  ));

  return (
    <div id="thirdCareer" className={styles.thirdCareer}>
      <div
        className="flex flex-col justify-center align-center lg:pt-20 lg:pb-44 lg:grid
							lg:grid-cols-2"
      >
        <div className="md:col-span-2">
          <div
            className="flex flex-col justify-center lg:px-24 lg:pb-32 px-5 align-center md:grid
							md:grid-cols-7
              flex"
          >
            <div className="md:col-span-4">
              <h1 className={styles.tituloPresentation}>
                As linguagens <br /> que falamos:{" "}
              </h1>
              <div className="mt-12">
                <span className={styles.textThirdCareer}>
                  Trabalhamos com <br /> diversas linguagens <br />
                  para obter os melhores <br />
                  resultados.
                </span>
              </div>
            </div>
            <div className="md:col-span-3 md:mt-0 mt-12">
              <div className={styles.areaLanguage}>
                {showScroll && (
                  <Typed
                    className={styles.language}
                    strings={[
                      "HTML,CSS,</br>JAVASCRIPT,</br>PHP, NODEJS,</br>POSTGRESQL,</br>MARIA DB,</br>VUE.JS,</br>REACT.JS,</br>REACT NATIVE",
                    ]}
                    typeSpeed={40}
                    backSpeed={25}
                  ></Typed>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-span-2 relative">
          <div
            className="grid
							lg:grid-cols-9
              md:grid-cols-7
              grid-cols-2
							gap-2
              flex
              lg:px-8
              mt-12
              lg:mt-0
              px-5
              "
          >
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdCareer;

import React, { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import Styles from "../styles/Job.module.scss";
import LaunchIcon from "@mui/icons-material/Launch";
import axios from "axios";
import { JobDTOS } from "../dtos/job.types";

const url = "https://madalozzoseguros.abler.com.br/api_v/v1/vagas.json";

const Job = () => {
  const [data, setData] = useState<JobDTOS[]>([]);

  const getDataAxios = async () => {
    try {
      const res = await axios.get(url);

      setData(res.data.vacancies.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataAxios();
  }, []);

  const arr = useMemo(() => {
    return data.filter(
      (a) =>
        a.attributes.title.includes("Desenvolvedor") ||
        a.attributes.title.includes("Marketing") ||
        a.attributes.title.includes("Desenvolvimento") ||
        a.attributes.title.includes("Back-end") ||
        a.attributes.title.includes("Front-end")
    );
  }, [data]);

  const content = arr.map((job) => (
    <div className={Styles.cardJob} key={job.id}>
      <Link passHref href={job.links.vacancy_url}>
        <a rel="noreferrer" target="_blank">
          <span className={Styles.textCardJob}>
            {job.attributes.title}
            <LaunchIcon className={Styles.icon} />
          </span>
        </a>
      </Link>
    </div>
  ));

  return (
    <div id="job" className={Styles.job}>
      <div
        className="flex justify-center align-center mt-12 lg:mt-0 px-5 lg:px-24 lg:pb-24 grid
							lg:grid-cols-5
							lg:gap-20
              flex"
      >
        <div className="col-span-2">
          <p className={Styles.presentation}>CARREIRAS</p>
          <h1 className={Styles.tituloPresentation}>Vagas</h1>
          <p className={Styles.textoPresentation}>
            Acreditamos que a tecnologia pode mudar o dia a dia das pessoas. Se
            você ama tecnologia, tem espírito cooperativo e quer evoluir na sua
            carreira, envie seu currículo e venha fazer parte do nosso time!
            Acompanhe nossos conteúdos!
          </p>
        </div>
        <div className="col-span-3 lg:my-10  mb-12">
          <div
            className="grid
							grid-cols-1
							gap-6
              flex
              mt-14
              justify-center
              align-center
              text-center
              "
          >
            {content}
          </div>
          <div className={Styles.areaSeeMore}>
            <div className={Styles.separator}>
              <div className={Styles.seeMore}>
                <Link passHref href="https://madalozzoseguros.abler.com.br/">
                  <a target="_blank" rel="noreferrer">
                    VER MAIS
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Job;

import Image from "next/image";
import Link from "next/link";
import mockup from "../assets/images/mockup.webp";
import play from "../assets/images/playStore.svg";
import google from "../assets/images/googlePlay.svg";
import madaseg from "../assets/images/logoMenor.svg";

import styles from "../styles/Portfolio.module.scss";

const About = () => {
  return (
    <div id="portfolio" className={styles.about}>
      <div
        className="flex flex-col justify-center align-center lg:pl-24 pl-5 lg:py-20 lg:grid
							lg:grid-cols-5
							lg:gap-12
              flex"
      >
        <div className="lg:col-span-2 lg:px-12 mt-12">
          <p className={styles.portfolio}>PORTFÓLIO</p>
          <h1 className={styles.tituloPortfolio}>Projetos únicos</h1>
          <div className="mt-8">
            <span className={styles.textPortfolio}>
              Criamos soluções únicas <br /> para o mercado segurador, <br />
              conheça nosso aplicativo <br />
              para gestão de seguros.
            </span>
          </div>
          <div
            className="flex justify-center flex-col pr-5 lg:pr-0 align-center md:grid
							md:grid-cols-2
							gap-5
              flex mt-8"
          >
            <div className={styles.linkImage}>
              <Link
                passHref
                href="https://play.google.com/store/apps/details?id=br.com.madaseg"
              >
                <Image
                  layout="responsive"
                  src={google}
                  alt="Link com Logo da Google Play"
                />
              </Link>
            </div>
            <div className={styles.linkImage}>
              <Link
                passHref
                href="https://apps.apple.com/br/app/madaseg/id1512022359"
              >
                <Image
                  layout="responsive"
                  src={play}
                  alt="Link com Logo da Play Store"
                />
              </Link>
            </div>
          </div>
          <div className="text-center mt-8 cursor-pointer">
            <Link passHref href="https://madaseg.com.br">
              <Image src={madaseg} alt="Link com Logo da madaseg" />
            </Link>
          </div>
        </div>
        <div className="lg:col-span-3 my-12 lg:my-0">
          <div className="flex flex-col text-center">
            <Image layout="responsive" src={mockup} alt="Imagem Mockup" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import * as Yup from 'yup';
import { createNewSchema } from '../utils/default_create_schema';

export const fullNameRegex = /^\s*([A-zÀ-ÿ]{2,})+(\s([A-zÀ-ÿ]{1,})+)+\s*$/gms;

export const form_LGPD_schema = Yup.object().shape(
  createNewSchema({
    name: Yup.string()
      .required('O campo "Nome" é obrigatório.')
      .matches(fullNameRegex, 'O campo "Nome" deve conter nome e sobrenome.'),
    email: Yup.string().email('E-mail inválido!').required('O campo "E-mail" é obrigatório.'),
    subject: Yup.string().required('O campo "Assunto" é obrigatório.'),
    message: Yup.string().required('O campo "Mensagem" é obrigatório.'),
  }),
);
import Image from "next/image";
import { CarrerProps } from "../dtos/carrer.types";

import Styles from "../styles/Career.module.scss";

const SecondCareer = (props: CarrerProps) => {
  const content = props.career.map((career) => (
    <div key={career.id}>
      <div className={Styles.imgCardSecondCareer}>
        <Image
          layout="responsive"
          alt="Imagens Madatech na Madalozzo Seguros"
          src={career.image}
        />
      </div>
    </div>
  ));

  return (
    <div id="secondCareer" className={Styles.secondCareer}>
      <div
        className="flex justify-center align-center py-12 md:py-24 lg:grid
							md:grid-cols-5
              flex-col
              flex"
      >
        <div className="col-span-3">
          <div
            className="grid
							grid-cols-3
							gap-3
              lg:px-8
              px-5
              "
          >
            {content}
          </div>
        </div>
        <div className="col-span-2 lg:mt-0 mt-12">
          <div className={Styles.rowSecondCareer}>
            <div className={Styles.areaHash}>
              <h2 className={Styles.hashCareer}>#resolvedores</h2>
            </div>
            <div className={Styles.areaText}>
              <p className={Styles.textoSecondCareer}>
                Seu conhecimento pode <br /> contribuir na <br />
                construção de soluções <br /> inteligentes para uma <br /> das
                maiores <br />
                seguradoras do país.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondCareer;
